import { createSlice, createSelector } from '@reduxjs/toolkit';
const initialState = {
  isDiscountInputEnabled: false,
  isDiscountPending: false,
  isAppliedTextVisible: false,
  discountCodeErrorMessage: '',
  discountAmount: {
    discountedValue: 0,
    discountedPercentage: 0
  }
};
const getDiscountCodeStore = state => state.discountCode;
export const getIsDiscountInputEnabled = createSelector([getDiscountCodeStore], discountCode => discountCode.isDiscountInputEnabled);
export const getIsDiscountPending = createSelector([getDiscountCodeStore], discountCode => discountCode.isDiscountPending);
export const getIsAppliedTextVisible = createSelector([getDiscountCodeStore], discountCode => discountCode.isAppliedTextVisible);
export const getDiscountCodeErrorMessage = createSelector([getDiscountCodeStore], discountCode => discountCode.discountCodeErrorMessage);
export const getDiscountAmount = createSelector([getDiscountCodeStore], discountCode => discountCode.discountAmount);
const discountCodeSlice = createSlice({
  name: 'discountCode',
  initialState,
  reducers: {
    setIsDiscountInputEnabled(state, action) {
      state.isDiscountInputEnabled = action.payload;
    },
    setIsDiscountPending(state, action) {
      state.isDiscountPending = action.payload;
    },
    setIsAppliedTextVisible(state, action) {
      state.isAppliedTextVisible = action.payload;
    },
    setDiscountCodeErrorMessage(state, action) {
      state.discountCodeErrorMessage = action.payload;
    },
    setDiscountAmount(state, action) {
      state.discountAmount = action.payload;
    }
  }
});
export const {
  setIsDiscountInputEnabled,
  setIsDiscountPending,
  setIsAppliedTextVisible,
  setDiscountCodeErrorMessage,
  setDiscountAmount
} = discountCodeSlice.actions;
export default discountCodeSlice.reducer;