import { useQuery } from 'data-fetching-client';
import { useSelector } from 'react-redux';
import { usePaymentFormContext } from '../context/PaymentFormContext';
import { GET_VALIDATION_ERRORS } from '../data/queries';
import { CHECKOUT_BE_VALIDATION_GATE } from '../constants/gates';
import { useCountryMetadata } from '../components/Address/FullAddress';
import { getBuyerOverridesLineItems } from '../state/BuyerOverrides/selectors';
export function useValidationErrors() {
  var _data$validationError, _data$validationError2;
  const {
    formState,
    checkoutSession
  } = usePaymentFormContext();
  const isUngatedToBEValidation = checkoutSession.get('gates').includes(CHECKOUT_BE_VALIDATION_GATE);
  const billingAddress = formState.get('billingAddress');
  const shippingAddress = formState.get('shippingAddress');
  const countryCode = formState.getIn(['billingAddress', 'country']);
  const buyerOverridesLineItems = useSelector(getBuyerOverridesLineItems);
  const {
    countryMetadata
  } = useCountryMetadata(countryCode);
  const shouldCheckPostalCode = countryMetadata && countryMetadata.fields.some(field => field.type === 'POSTAL_CODE');
  const variables = {
    email: formState.get('email'),
    paymentMethod: formState.get('paymentMethod'),
    checkoutBuyerOverrides: {
      lineItems: buyerOverridesLineItems,
      discountCode: formState.get('discountCode') || null
    },
    billingInfo: billingAddress && {
      paymentsAddress: Object.assign({
        line1: billingAddress.get('address'),
        line2: billingAddress.get('address2')
      }, billingAddress.toJS()),
      fullName: billingAddress.get('fullName'),
      shouldCheckPostalCode
    },
    shippingInfo: shippingAddress && {
      paymentsAddress: Object.assign({
        line1: shippingAddress.get('address'),
        line2: shippingAddress.get('address2')
      }, shippingAddress.toJS()),
      firstName: shippingAddress.get('firstName'),
      lastName: shippingAddress.get('lastName'),
      shouldCheckPostalCode
    }
  };
  const {
    data,
    loading
  } = useQuery(GET_VALIDATION_ERRORS, {
    skip: !isUngatedToBEValidation || formState.get('loading'),
    variables
  });
  return {
    formFieldValidationErrors: (data === null || data === void 0 || (_data$validationError = data.validationErrors) === null || _data$validationError === void 0 || (_data$validationError = _data$validationError.formFieldValidationErrors) === null || _data$validationError === void 0 || (_data$validationError = _data$validationError[0]) === null || _data$validationError === void 0 ? void 0 : _data$validationError.errors) || [],
    subtotalsValidationErrors: (data === null || data === void 0 || (_data$validationError2 = data.validationErrors) === null || _data$validationError2 === void 0 || (_data$validationError2 = _data$validationError2.subtotalsValidationErrors) === null || _data$validationError2 === void 0 || (_data$validationError2 = _data$validationError2[0]) === null || _data$validationError2 === void 0 ? void 0 : _data$validationError2.errors) || [],
    isValid: data === null || data === void 0 ? void 0 : data.validationErrors.isValid,
    loading
  };
}