
(function() {
  var result = { translations: { "th": { "i18n": { "duration": { "seconds": "{{ seconds }} \u0E27\u0E34\u0E19\u0E32\u0E17\u0E35", "minutes": "{{ minutes }} \u0E19\u0E32\u0E17\u0E35", "hours": "{{ hours }} \u0E0A\u0E31\u0E48\u0E27\u0E42\u0E21\u0E07", "hoursAndMinutes": "{{ hours }} \u0E0A\u0E31\u0E48\u0E27\u0E42\u0E21\u0E07 {{ minutes }} \u0E19\u0E32\u0E17\u0E35", "days": "{{ days }} \u0E27\u0E31\u0E19", "shortForm": { "seconds": "{{ seconds }} \u0E27\u0E34\u0E19\u0E32\u0E17\u0E35", "minutes": "{{ minutes }} \u0E19\u0E32\u0E17\u0E35", "hours": "{{ hours }} \u0E0A\u0E21.", "days": "{{ days }} \u0E27\u0E31\u0E19", "hoursAndMinutes": "{{ hours }} \u0E0A\u0E21. {{ minutes }} \u0E19\u0E32\u0E17\u0E35" } }, "dateTime": { "quarterAndYear": "\u0E44\u0E15\u0E23\u0E21\u0E32\u0E2A {{quarterNumber}} {{yearNumber}}" }, "numberRepresentation": { "fraction": "{{ numerator }}/{{ denominator }}", "numberWithCurrencyCode": "{{amountNumberValue}} {{currencyCode}}", "phoneNumberWithExtension": "{{phoneNumber}} \u0E15\u0E48\u0E2D {{extension}}" } } } } };
  result.translationsLoaded = {
  "I18n": {
    "th": "static-7.1198"
  }
};
  result.translationsAvailable = {
  "I18n": {
    "af": "static-7.1198",
    "ar-eg": "static-7.1198",
    "bg": "static-7.1198",
    "bn": "static-7.1198",
    "ca-es": "static-7.1198",
    "cs": "static-7.1198",
    "da": "static-7.1198",
    "de": "static-7.1198",
    "el": "static-7.1198",
    "en-gb": "static-7.1198",
    "en": "static-7.1198",
    "es-mx": "static-7.1198",
    "es": "static-7.1198",
    "et": "static-7.1198",
    "fi": "static-7.1198",
    "fr-ca": "static-7.1198",
    "fr": "static-7.1198",
    "he-il": "static-7.1198",
    "hi": "static-7.1198",
    "hr": "static-7.1198",
    "hu": "static-7.1198",
    "id": "static-7.1198",
    "it": "static-7.1198",
    "ja": "static-7.1198",
    "ko": "static-7.1198",
    "lt": "static-7.1198",
    "ms": "static-7.1198",
    "nl": "static-7.1198",
    "no": "static-7.1198",
    "pl": "static-7.1198",
    "pt-br": "static-7.1198",
    "pt-pt": "static-7.1198",
    "ro": "static-7.1198",
    "ru": "static-7.1198",
    "sk": "static-7.1198",
    "sl": "static-7.1198",
    "sv": "static-7.1198",
    "th": "static-7.1198",
    "tl": "static-7.1198",
    "tr": "static-7.1198",
    "uk": "static-7.1198",
    "vi": "static-7.1198",
    "x-pseudo": "static-7.1198",
    "zh-cn": "static-7.1198",
    "zh-hk": "static-7.1198",
    "zh-tw": "static-7.1198"
  }
};
  hns('I18n', result);
  if (typeof I18n === 'object' && I18n.trigger) { I18n.trigger('loaded:I18n:th', {version: 'static-7.1198'}); }
})();


