import noAuthClient from 'hub-http/clients/noAuthApiClient';
import { catchAndRethrowSensitiveNetworkError
// @ts-expect-error Untyped module
} from 'crm_data/bCommerce/monitoring/errorUtils';
import { DISCOUNT_CODE_NOT_VALID } from '../constants/ValidationErrors';
export const fetchBuyerOverridesSubtotals = ({
  buyerOverrides,
  paymentSessionId
}) => noAuthClient.post(`payments-checkout/v1/checkout-sessions/${paymentSessionId}/subtotals`, {
  data: {
    buyerOverrides
  }
}).catch(e => {
  // We don't want to send a Sentry error when a discount code is not valid
  if (JSON.parse(e.data).subCategory !== DISCOUNT_CODE_NOT_VALID) {
    catchAndRethrowSensitiveNetworkError(e);
  }
  throw e;
});
export const fetchBuyerOverridesPreviewSubtotals = ({
  buyerOverrides,
  currencyCode,
  discount,
  adjustments,
  lineItems,
  portalId
}) => noAuthClient.post('payments-checkout/v1/checkout-session-previews/subtotals', {
  query: {
    portalId
  },
  data: {
    buyerOverrides,
    currencyCode,
    discount,
    lineItems,
    adjustments
  }
}).catch(e => {
  // We don't want to send a Sentry error when a discount code is not valid
  if (JSON.parse(e.data).subCategory !== DISCOUNT_CODE_NOT_VALID) {
    catchAndRethrowSensitiveNetworkError(e);
  }
  throw e;
});