
(function() {
  var result = { translations: { "el": { "i18n": { "duration": { "seconds": "{{ seconds }} \u03B4\u03B5\u03C5\u03C4.", "minutes": "{{ minutes }} \u03BB\u03B5\u03C0\u03C4.", "hours": "{{ hours }} \u03CE\u03C1.", "hoursAndMinutes": "{{ hours }} \u03CE\u03C1. {{ minutes }} \u03BB\u03B5\u03C0\u03C4.", "days": "{{ days }} \u03B7\u03BC\u03AD\u03C1\u03B5\u03C2", "shortForm": { "seconds": "{{ seconds }}\u03B4.", "minutes": "{{ minutes }}\u03BB.", "hours": "{{ hours }}\u03CE\u03C1.", "days": "{{ days }}\u03B7\u03BC.", "hoursAndMinutes": "{{ hours }}\u03CE\u03C1. {{ minutes }}\u03BB." } }, "dateTime": { "quarterAndYear": "{{quarterNumber}}\u03BF \u03C4\u03C1\u03AF\u03BC. {{yearNumber}}" }, "numberRepresentation": { "fraction": "{{ numerator }}/{{ denominator }}", "numberWithCurrencyCode": "{{currencyCode}} {{amountNumberValue}}", "phoneNumberWithExtension": "{{phoneNumber}}, \u03B5\u03C3\u03C9\u03C4. {{extension}}" } } } } };
  result.translationsLoaded = {
  "I18n": {
    "el": "static-7.1198"
  }
};
  result.translationsAvailable = {
  "I18n": {
    "af": "static-7.1198",
    "ar-eg": "static-7.1198",
    "bg": "static-7.1198",
    "bn": "static-7.1198",
    "ca-es": "static-7.1198",
    "cs": "static-7.1198",
    "da": "static-7.1198",
    "de": "static-7.1198",
    "el": "static-7.1198",
    "en-gb": "static-7.1198",
    "en": "static-7.1198",
    "es-mx": "static-7.1198",
    "es": "static-7.1198",
    "et": "static-7.1198",
    "fi": "static-7.1198",
    "fr-ca": "static-7.1198",
    "fr": "static-7.1198",
    "he-il": "static-7.1198",
    "hi": "static-7.1198",
    "hr": "static-7.1198",
    "hu": "static-7.1198",
    "id": "static-7.1198",
    "it": "static-7.1198",
    "ja": "static-7.1198",
    "ko": "static-7.1198",
    "lt": "static-7.1198",
    "ms": "static-7.1198",
    "nl": "static-7.1198",
    "no": "static-7.1198",
    "pl": "static-7.1198",
    "pt-br": "static-7.1198",
    "pt-pt": "static-7.1198",
    "ro": "static-7.1198",
    "ru": "static-7.1198",
    "sk": "static-7.1198",
    "sl": "static-7.1198",
    "sv": "static-7.1198",
    "th": "static-7.1198",
    "tl": "static-7.1198",
    "tr": "static-7.1198",
    "uk": "static-7.1198",
    "vi": "static-7.1198",
    "x-pseudo": "static-7.1198",
    "zh-cn": "static-7.1198",
    "zh-hk": "static-7.1198",
    "zh-tw": "static-7.1198"
  }
};
  hns('I18n', result);
  if (typeof I18n === 'object' && I18n.trigger) { I18n.trigger('loaded:I18n:el', {version: 'static-7.1198'}); }
})();


